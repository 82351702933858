<template>
    <Setup-Step
        @yes="f => addNew(null, 1)"
        @no="next"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="products"
        title="Products">
        <template v-slot:body>
            <Setup-Block>
                <v-tabs-items v-model="stage" touchless>
                    <v-tab-item>
                        <BT-List-Endless
                            :canSearch="false"
                            :loadingMsg.sync="loadingMsg"
                            maxHeight="60vh"
                            navigation="products"
                            :refreshToggle="refreshList">
                            <template v-slot="{ item }">
                                <v-list-item-avatar>
                                    <v-img :src="productLogoURL(item.id)" class="my-auto">
                                        <template v-slot:placeholder><v-icon class="primary--text" size="40">mdi-cube-outline</v-icon></template>
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.productName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.abbreviation }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn small text @click="addNew(item, 3)">change</v-btn>
                                </v-list-item-action>
                            </template>
                        </BT-List-Endless>

                        <p class="my-4">Would you like to add a product?</p>
                    </v-tab-item>

                    <v-tab-item>
                        <div>
                            <BT-Btn
                                icon="mdi-plus"
                                label="Create"
                                @click="addNew(null, 2)" />

                            <BT-File-Select
                                v-if="!isLengthyArray(newItems)"
                                icon="mdi-file-delimited"
                                label="Import CSV"
                                @fileSelected="importProducts" />
                            <BT-Btn
                                v-else
                                icon="mdi-file-delimited"
                                label="Import CSV"
                                @click="stage += 2" />
                            
                            <BT-Btn
                                icon="mdi-download-network"
                                label="Import From Service"
                                @click="showServices = true" />
                        </div>
                        <div>
                            <BT-Btn
                                @click="generateProductCSVFile"
                                buttonClass="primary--text text-body-2" 
                                inline 
                                label="Download CSV Template" 
                                text />
                        </div>
                        
                        <div v-if="showServices">
                            <v-divider class="my-2" />
                            <v-subheader>External Services</v-subheader>
                            <External-Parties-Blade
                                @selected="selectService"
                                inline
                                small
                                syncNavigation="product-syncing" />
                        </div>
                    </v-tab-item>

                    <v-tab-item>
                        <BT-List-Endless
                            :canSearch="false"
                            :items="newItems"
                            maxHeight="60vh">
                            <template v-slot="{ item }">
                                <v-list-item-avatar>
                                    <v-img :src="productLogoURL(item.id)" class="my-auto">
                                        <template v-slot:placeholder><v-icon class="primary--text" size="40">mdi-cube-outline</v-icon></template>
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.productName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.abbreviation }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row no-gutters>
                                        <v-btn small text @click="addNew(item, 1)">Save As</v-btn>
                                        <v-btn small text @click="save(item)">Save</v-btn>
                                    </v-row>
                                </v-list-item-action>
                            </template>
                        </BT-List-Endless>
                    </v-tab-item>

                    <v-tab-item>
                        <BT-Field-String
                            label="Product Name"
                            v-model="newItem.productName"
                            isEditing />

                        <BT-Field-String
                            label="Abbreviation"
                            v-model="newItem.abbreviation"
                            isEditing />

                        <BT-Btn 
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </v-tab-item>

                    <v-tab-item>
                        <BT-Field-Number
                            v-model.number="newItem.length"
                            label="Length (mm)"
                            isEditing />
                        
                        <BT-Field-Number
                            v-model.number="newItem.width"
                            label="Width (mm)"
                            isEditing />
                        
                        <BT-Field-Number
                            v-model.number="newItem.height"
                            label="Height (mm)"
                            isEditing />
                        
                        <BT-Field-Number
                            v-model.number="newItem.volume"
                            label="Volume (Ltr)"
                            isEditing />

                        <BT-Field-Number
                            v-model.number="newItem.weight"
                            label="Weight (Kg)"
                            isEditing />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </v-tab-item>

                    <v-tab-item>
                        <v-row>
                            <v-col cols="6" sm="4" md="3">
                                <BT-Square-Check
                                    falseIcon="mdi-currency-usd"
                                    isEditing
                                    label="Sold"
                                    outlined
                                    rounded
                                    trueIcon="mdi-currency-usd"
                                    v-model="newItem.isSold" />
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <BT-Square-Check
                                    falseIcon="mdi-cart"
                                    isEditing
                                    label="Acquired"
                                    outlined
                                    rounded
                                    trueIcon="mdi-cart"
                                    v-model="newItem.isAcquired" />
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <BT-Square-Check
                                    falseIcon="mdi-calendar"
                                    isEditing
                                    label="Perishable"
                                    outlined
                                    rounded
                                    trueIcon="mdi-calendar"
                                    v-model="newItem.isPerishable" />
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <BT-Square-Check
                                    falseIcon="mdi-fridge"
                                    isEditing
                                    label="Refrigerate"
                                    outlined
                                    rounded
                                    trueIcon="mdi-fridge"
                                    v-model="newItem.isTemperatureSensitive" />
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <BT-Square-Check
                                    falseIcon="mdi-keyboard-return"
                                    isEditing
                                    label="Returnable"
                                    outlined
                                    rounded
                                    trueIcon="mdi-keyboard-return"
                                    v-model="newItem.isReturnable" />
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <BT-Square-Check
                                    falseIcon="mdi-factory"
                                    isEditing
                                    label="Manufacture"
                                    outlined
                                    rounded
                                    trueIcon="mdi-factory"
                                    v-model="newItem.isManufactured" />
                            </v-col>
                            <v-col cols="12">
                                <BT-Btn
                                    buttonClass="primary mx-1"
                                    inline
                                    label="Next"
                                    @click="stage += 1" />
                            </v-col>
                        </v-row>

                        <!-- <p>Do you sell this product?</p>

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(newItem, 'isSold', false)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(newItem, 'isSold', true)" /> -->
                    </v-tab-item>

                    <!-- <v-tab-item>
                        <p>Do you buy this product?</p>

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(newItem, 'isAcquired', false)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(newItem, 'isAcquired', true)" />
                    </v-tab-item>

                    <v-tab-item>
                        <p>Does this product expire?</p>

                        <Setup-Info>
                            Products that expire are marked as perishable and are given an expiry span.
                        </Setup-Info>

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(newItem, 'isPerishable', false)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(newItem, 'isPerishable', true)" />
                    </v-tab-item> -->

                    <v-tab-item>
                        <p>What is the lifespan of this product? (in days)</p>

                        <BT-Field-Number
                            v-model.number="newItem.expiryDays"
                            label="Expiry Days"
                            isEditing />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </v-tab-item>

                    <!-- <v-tab-item>
                        <p>Is this product refrigerated?</p>

                        <Setup-Info>
                            Temperature sensitive products require a temperature on delivery.
                        </Setup-Info>

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(newItem, 'isTemperatureSensitive', false)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(newItem, 'isTemperatureSensitive', true)" />
                    </v-tab-item> -->

                    <!-- <v-tab-item>
                        <p>Is this a product that will be returned when a customer is finished with it?</p>

                        <Setup-Example>
                            You sell glass bottles of milk and once the milk has been used the bottle is returned.
                        </Setup-Example>
                        
                        <Setup-Example>
                            You loan out equipment and would like to track where each tool is and make sure it is returned.
                        </Setup-Example>
                        
                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(newItem, 'isReturnable', false)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(newItem, 'isReturnable', true)" />
                    </v-tab-item> -->

                    <!-- <v-tab-item>
                        <p>Is this a product that you make?</p>

                        <Setup-Info>
                            This means you can create new batches of this product with the production tool.
                        </Setup-Info>

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(newItem, 'isManufactured', false)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(newItem, 'isManufactured', true)" />
                    </v-tab-item> -->

                    <v-tab-item>
                        <p>What is the format of this product's batchcode?</p>

                        <BT-Field-String
                            v-model="newItem.batchcodeFormat"
                            label="Batchcode Format"
                            isEditing />

                        <v-list-item>
                            <v-list-item-content>
                                <div>Sample: {{ $BlitzIt.auth.createTZ(newItem.batchcodeFormat) }}</div>
                                <a href="https://moment.github.io/luxon/#/formatting?id=table-of-tokens" target="_blank" class="primary--text">What letters do I use to format?</a>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <Setup-Info>
                            Batchcodes are assigned to each new batch and you can define the format of those codes using the date of manufacture as the input.
                        </Setup-Info>

                        <Setup-Example>
                            A product with this format: yyyyMMdd'G5G5' and a DOM of '1/1/2011' will generate: 20110101G5G5.
                        </Setup-Example>
                        
                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="save(newItem)" />
                    </v-tab-item>

                    <v-tab-item v-if="orderingItem != null">
                        <p>Would you like to upload an image of this product?</p>
                        
                        <BT-Image-Edit
                            canEdit
                            :height="120"
                            :id="orderingItem.id"
                            justify-center align-center
                            navigation="product-image"
                            :src="productLogoURL(orderingItem.id) + '?' + cacheData"
                            @uploaded="cacheData = new Date().getTime()"
                            :width="120" />
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </v-tab-item>

                    <v-tab-item v-if="orderingItem != null">
                        <p>Is this product GST chargeable?</p>
                        
                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(orderingItem, 'taxType', null)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(orderingItem, 'taxType', 'GST')" />
                    </v-tab-item>

                    <v-tab-item v-if="orderingItem != null">
                        <p>Do you want this product to be visible to the public?</p>
                        
                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="No"
                            @click="nextStage(orderingItem, 'isPublicProduct', false, 2)" />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Yes"
                            @click="nextStage(orderingItem, 'isPublicProduct', true)" />
                    </v-tab-item>

                    <v-tab-item v-if="orderingItem != null">
                        <p>Give a short blurb about this product that the public will see.</p>
                        
                        <BT-Field-Text-Area
                            label="Description"
                            v-model="orderingItem.publicDescription"
                            isEditing />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="saveOrdering" />
                    </v-tab-item>

                </v-tabs-items>
            </Setup-Block>
        </template>
    </Setup-Step>
</template>

<script>
import { toCamelCase } from '~helpers';

export default {
    name: 'Product-Setup',
    components: {
        BTFileSelect: () => import('~components/BT-File-Select.vue'),
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        ExternalPartiesBlade: () => import('~home/external-parties/External-Parties-Blade.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            cacheData: new Date().getTime(),
            currentExternalParty: null,
            loadingMsg: null,
            newItem: {},
            newItems: [],
            orderingItem: null,
            refreshList: false,
            showServices: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j, stepsNum) {
            this.newItem = j || { };

            this.stage += stepsNum;
        },
        async importProducts(file) {
            if (!this.isLengthyArray(this.newItems)) {
                this.newItems = await this.importProductsFromFile(file);
            }

            var products = await this.$BlitzIt.store.getAll('products');

            this.newItems = this.newItems.filter(x => !products.some(p => p.productName == x.productName));

            this.stage += 1;
        },
        next(nextFunc) {
            nextFunc();
        },
        nextStage(obj, key, val, stageNum = 1) {
            obj[key] = val;
            this.stage += stageNum;
        },
        async save(item) {
            try {
                this.loadingMsg = 'Saving';
                
                var res = null;

                if (item.id == null) {
                    res = await this.$BlitzIt.store.post('products', item);
                    
                    //try remove from import
                    var nInd = this.newItems.findIndex(x => x.productName == item.productName);
                    if (nInd >= 0) {
                        this.newItems.splice(nInd, 1);
                    }

                    //wait a couple seconds
                    await this.twiddleThumbs(2000);
                }
                else {
                    res = await this.$BlitzIt.store.patch('products', item);
                }
                
                item.id = res.id;
                item.rowVersion = res.rowVersion;

                this.orderingItem = await this.$BlitzIt.store.get('product-ordering', item.id);
                this.refreshList = !this.refreshList;

                this.stage = 8;
            }
            finally {
                this.loadingMsg = null;
            }
            
        },
        async saveOrdering() {
            try {
                this.loadingMsg = 'Saving';
                
                var res = await this.$BlitzIt.store.patch('product-ordering', this.orderingItem);
                
                this.orderingItem.rowVersion = res.rowVersion;

                if (this.isLengthyArray(this.newItems)) {
                    this.stage = 2;
                }
                else {
                    this.stage = 0;
                }
            }
            finally {
                this.loadingMsg = null;
            }
            
        },
        async selectService(externalObj) {
            if (externalObj != null && externalObj.credential != null && externalObj.credential.isConnected) {
                this.currentExternalParty = externalObj;
                try {
                    this.loadingMsg = `Searching ${externalObj.optionName}`;
                    var syncRes = await this.$BlitzIt.api.getAll('product-syncing', { partyID: externalObj.id, onlyUnsynced: true });
                    var convertFunc = this.$BlitzIt.navigation.findConvertFunc('product-syncing', externalObj.id);
                    if (convertFunc != null) {
                        this.newItems = JSON.parse(syncRes.data.data, toCamelCase).map(x => convertFunc(x));
                    }
                    else {
                        this.newItems = JSON.parse(syncRes.data.data, toCamelCase);
                    }
                    
                    this.stage += 1;
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        }
    }
}
</script>